import {
  ArrowPathIcon,
  CheckIcon,
  PlusCircleIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import * as React from "react";
import { useSpinDelay } from "spin-delay";
import { cn } from "~/utils/misc.tsx";
import { Button, type ButtonProps } from "./button.tsx";

export const StatusButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & {
    status: "pending" | "success" | "error" | "idle";
    message?: string | null;
    spinDelay?: Parameters<typeof useSpinDelay>[1];
  }
>(({ message, status, className, children, spinDelay, ...props }, ref) => {
  const delayedPending = useSpinDelay(status === "pending", {
    delay: 400,
    minDuration: 300,
    ...spinDelay,
  });
  const companion = {
    pending: delayedPending ? (
      <div className="inline-flex h-6 w-6 items-center justify-center">
        <ArrowPathIcon className="animate-spin h-6 w-6 text-white" />
      </div>
    ) : null,
    success: (
      <div className="inline-flex h-6 w-6 items-center justify-center">
        <CheckIcon className="h-6 w-6 text-white" />
      </div>
    ),
    error: (
      <div className="inline-flex h-6 w-6 items-center justify-center rounded-full bg-destructive">
        <XCircleIcon className="h-6 w-6 text-white" />
      </div>
    ),
    idle:
      props.variant === "destructive" ? (
        <div className="inline-flex h-6 w-6 items-center justify-center">
          <TrashIcon className="h-6 w-6 text-white" />
        </div>
      ) : (
        <div className="inline-flex h-6 w-6 items-center justify-center">
          <PlusCircleIcon className="h-6 w-6 text-white" />
        </div>
      ),
  }[status];

  return (
    <div>
      <Button
        ref={ref}
        className={cn("flex justify-center gap-4 items-center", className)}
        {...props}
      >
        <div>{children}</div>
        {companion}
        {/* {message ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>{companion}</TooltipTrigger>
            <TooltipContent>{message}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        companion
      )} */}
      </Button>
      {message && (
        <p className="text-[10px] ml-2 mt-1 text-foreground-destructive">
          {message}
        </p>
      )}
    </div>
  );
});
StatusButton.displayName = "Button";
